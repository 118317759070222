<template>
  <b-list-group-item v-if="value">
    <div
      class="font-weight-bold"
      :class="textColor"
    >
      {{ title }}
    </div>
    {{ value }}
  </b-list-group-item>
</template>
<script>
import { BListGroupItem } from 'bootstrap-vue'

export default {
  name: 'ListItem',
  components: {
    BListGroupItem,
  },
  props: {
    title: {
      type: String,
      required: true,
    },
    value: {
      type: String,
      required: false,
      default: null,
    },
    textColor: {
      type: String,
      required: false,
      default: 'text-primary',
    },
  },
}
</script>

<style scoped>

</style>

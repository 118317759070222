<template>
  <b-form-group
    label="Servis Danışmanı"
    label-for="id_com_user"
  >
    <validation-provider
      #default="{ errors }"
      name="Danışman"
      rules="required"
    >
      <v-select
        id="id_com_user"
        v-model="service.id_com_user"
        label="name"
        :reduce="item => item.id"
        :options="users"
        placeholder="Seçiniz"
        :disabled="!service.id_com_brand || !service.id_com_location"
        @input="getTimesData"
      >
        <template v-slot:option="option">
          <div>
            {{ option.name }}
          </div>
          <div>
            <small class="text-warning">{{ option.brand }} - <span class="text-primary badge badge-sm badge-light">{{ option.user_type }}</span></small>
          </div>
          <div>
            <small class="text-muted">{{ option.location }}</small>
          </div>
        </template>
        <template v-slot:selected-option="option">
          <div>
            {{ option.name }} <span class="text-primary font-small-2"><span class="badge badge-primary">{{ option.brand }}</span> <span class="badge badge-secondary"> {{ option.location }} </span> <span class="text-muted badge badge-light">{{ option.user_type }}</span></span>
          </div>
        </template>
      </v-select>
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'Users',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    users() {
      return this.$store.getters['users/getUsers']
    },
  },
  created() {
    localize('tr')
  },
  methods: {
    getTimesData() {
      this.service.appointment_date = null
      this.service.appointment_time = null
      if (this.service.id_com_user && this.service.appointment_date) {
        this.$store.dispatch('serviceTracing/getTimes', {
          id_com_user: this.service.id_com_user,
          appointment_date: this.service.appointment_date,
        })
      } else {
        this.$store.commit('serviceTracing/SET_TIMES', {
          data: [],
          serviceData: [],
        })
      }
    },
  },
}
</script>

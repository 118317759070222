<template>
  <b-form-group
    label="Vites Tipi"
    label-for="gear"
  >
    <v-select
      id="gear"
      v-model="car.id_com_gear"
      label="title"
      :reduce="gear => gear.id"
      :options="gears"
      placeholder="Vites Tipi"
    />
  </b-form-group>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
    gears() {
      return this.$store.getters['gears/getGears']
    },
  },
  created() {
    this.getGears()
  },
  methods: {
    getGears() {
      this.$store.dispatch('gears/gearsList', {
        select: ['com_gear.id AS id', 'com_gear.title AS title'],
      })
    },
  },
}
</script>

<style scoped>
</style>

<template>
  <b-form-group
    label="Plaka"
    label-for="license_plate"
  >
    <validation-provider
      #default="{ errors }"
      name="Plaka"
      rules="required"
    >
      <b-form-input
        id="license_plate"
        v-model="car.license_plate"
        placeholder="Plaka"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup, BFormInput,
} from 'bootstrap-vue'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    BFormInput,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>
<style scoped>
</style>

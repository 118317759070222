<template>
  <b-form-group
    label="Yakıt Tipi"
    label-for="fuel"
  >
    <v-select
      id="fuel"
      v-model="car.id_com_fuel"
      label="title"
      :reduce="fuel => fuel.id"
      :options="fuels"
      placeholder="Yakıt Tipi"
    />
  </b-form-group>
</template>
<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'

export default {
  name: 'Brands',
  components: {
    BFormGroup,
    vSelect,
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
    fuels() {
      return this.$store.getters['fuels/getFuels']
    },
  },
  created() {
    this.getFuels()
  },
  methods: {
    getFuels() {
      this.$store.dispatch('fuels/fuelsList', {
        select: ['com_fuel.id AS id', 'com_fuel.title AS title'],
      })
    },
  },
}
</script>

<style scoped>
</style>

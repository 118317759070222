<template>
  <b-form-group
    label="Model Yılı"
    label-for="myear"
  >
    <cleave
      id="myear"
      v-model="car.myear"
      class="form-control"
      :raw="false"
      :options="options.myear"
      placeholder="Model Yılı"
    />
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import Cleave from 'vue-cleave-component'

export default {
  name: 'ModelYear',
  components: {
    BFormGroup,
    Cleave,
  },
  data() {
    return {
      options: {
        myear: {
          blocks: [4],
          numericOnly: true,
        },
      },
    }
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
  },
}
</script>

<style scoped></style>

<template>
  <b-form-group
    label="Model"
    label-for="model"
  >
    <validation-provider
      #default="{ errors }"
      name="Model"
      rules="required"
    >
      <v-select
        id="model"
        v-model="car.id_com_swap_model"
        label="title"
        :reduce="model => model.id"
        :options="models"
        placeholder="Model"
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>
<script>
import { ValidationProvider, localize } from 'vee-validate'
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  name: 'Models',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      locale: 'tr',
    }
  },
  computed: {
    car() {
      return this.$store.getters['cars/getCar']
    },
    models() {
      return this.$store.getters['swapmodels/getSwapmodels']
    },
  },
  created() {
    localize(this.locale)
  },
}
</script>

<style scoped>
</style>

<template>
  <b-form-group
    label="Müşteri Talepleri"
    label-for="customer_request"
  >
    <b-form-textarea
      v-model="service.customer_request"
      placeholder="Müşteri Talepleri"
      rows="6"
    />
  </b-form-group>
</template>

<script>
import { BFormGroup, BFormTextarea } from 'bootstrap-vue'

export default {
  name: 'CustomerRequest',
  components: {
    BFormGroup,
    BFormTextarea,
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
  },
}
</script>

<style scoped>

</style>

<template>
  <div>
    <customer-view
      v-if="customer.id"
      :customer-id="customer.id"
    />
  </div>
</template>
<script>
import CustomerView from '@/views/Customers/CustomerView.vue'

export default {
  name: 'Customer',
  components: {
    CustomerView,
  },
  computed: {
    customer() {
      return this.$store.getters['customers/getCustomer']
    },
  },
}
</script>

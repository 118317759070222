<template>
  <div>
    <b-form-group
      label="Randevu Tarihi"
      label-for="appointment_date"
    >
      <validation-provider
        #default="{ errors }"
        name="Randevu Tarihi"
        rules="required"
      >
        <b-form-datepicker
          id="appointment_date"
          v-model="service.appointment_date"
          v-bind="{labelNoDateSelected: 'Tarih Seçiniz',labelHelp: 'Ok tuşlarıyla takvimde gezinin'}"
          locale="tr"
          start-weekday="1"
          :disabled="!service.id_com_user"
          @input="getTimesData"
        />
        <small class="text-danger">{{ errors[0] }}</small>
      </validation-provider>
    </b-form-group>
    <b-row>
      <b-col
        v-for="(item,index) in timesData.data"
        :key="index"
        cols="12"
        md="2"
      >
        <b-button
          class="mb-1"
          :variant="item.status? (item.time === service.appointment_time)? 'danger' : 'success' : 'secondary'"
          size="sm"
          block
          :disabled="!item.status"
          @click="setTime(index)"
        >
          <FeatherIcon
            :icon="item.status? (item.time === service.appointment_time)? 'CheckIcon' : 'ClockIcon' : 'XIcon'"
          />
          {{ item.time }}
        </b-button>
      </b-col>
    </b-row>
    <vue-perfect-scrollbar
      v-if="timesData.serviceData.length > 0"
      class="scroll-area mb-2"
      :settings="settings"
    >
      <b-table
        responsive="sm"
        small
        :fields="fields"
        :items="timesData.serviceData"
        striped
      >
        <template #cell(customer_name)="data">
          <div>{{ data.item.customer_name }}</div>
          <div
            v-if="data.item.company"
            class="font-small-2 text-primary"
          >
            {{ data.item.company }}
          </div>
        </template>
        <template #cell(car_brand)="data">
          <div>
            {{ data.item.car_brand }}
            {{ data.item.car_model }}
          </div>
          <div
            v-if="data.item.license_plate"
            class="font-small-2 text-primary"
          >
            {{ data.item.license_plate }}
          </div>
        </template>
        <template #cell(service_type)="data">
          <b-badge
            v-for="(type,key) in data.item.service_type"
            :key="key"
            class="mb-1"
            variant="primary"
          >
            {{ type }}
          </b-badge>
        </template>
      </b-table>
    </vue-perfect-scrollbar>
  </div>
</template>

<script>
import {
  BRow,
  BCol,
  BFormGroup,
  BFormDatepicker,
  BButton,
  BTable,
  BBadge,
} from 'bootstrap-vue'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'
import VuePerfectScrollbar from 'vue-perfect-scrollbar'

export default {
  name: 'Users',
  components: {
    BRow,
    BCol,
    BFormGroup,
    BFormDatepicker,
    BButton,
    BTable,
    BBadge,
    ValidationProvider,
    VuePerfectScrollbar,
  },
  data() {
    return {
      settings: {
        maxScrollbarLength: 60,
      },
      required,
      fields: [
        {
          key: 'customer_name',
          label: 'MÜŞTERİ ADI',
          tdClass: 'font-small-2',
        },
        {
          key: 'car_brand',
          label: 'ARAÇ BİLGİSİ',
          tdClass: 'font-small-2',
        },
        {
          key: 'service_type',
          label: 'İŞLEM',
          tdClass: 'font-small-2 text-center',
        },
        {
          key: 'service_status',
          label: 'DURUM',
          tdClass: 'font-small-2',
        },
        {
          key: 'service_user',
          label: 'DANIŞMAN',
          tdClass: 'font-small-2',
        },
        {
          key: 'open_user',
          label: 'RANDEVUYU AÇAN',
          tdClass: 'font-small-2',
        },
        {
          key: 'appointment_time',
          label: 'SAAT',
          tdClass: 'font-small-2',
        },
      ],
    }
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    timesData() {
      return this.$store.getters['serviceTracing/getTimesData']
    },
  },
  created() {
    localize('tr')
    this.getTimesData()
  },
  methods: {
    setTime(index) {
      if (this.timesData.data[index].status) {
        const { time } = this.timesData.data[index]
        this.service.appointment_time = time
      }
    },
    getTimesData() {
      if (this.service.id_com_user && this.service.appointment_date) {
        this.$store.dispatch('serviceTracing/getTimes', {
          id_com_user: this.service.id_com_user,
          appointment_date: this.service.appointment_date,
          id_com_service_tracing: this.service.id,
        })
      }
    },
  },
}
</script>
<style lang="scss" scoped>
.scroll-area {
  position: relative;
  margin: 0;
  width: 100%;
  max-height: 250px;
}
</style>

<template>
  <b-list-group-item>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <div class="d-flex justify-content-start align-items-center">
          <div class="mr-1">
            <b-button
              v-b-tooltip.hover.v-success
              variant="flat-success"
              class="btn-icon"
              pill
              title="Görüntüle"
              target="_blank"
              :href="'/crm/view/' + itemData.id"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
          </div>
          <div>
            <div class="font-small-2">
              Başvuru
            </div>
            <div class="text-danger font-weight-bold">
              {{ moment(itemData.created).format('DD.MM.YYYY') }}
            </div>
            <div class="text-danger font-small-2">
              {{ itemData.feedback_type }} ({{ itemData.crm_level }})
            </div>
            <div class="text-primary font-small-2">
              {{ itemData.crm_status }}
            </div>
            <div class="text-muted font-small-2">
              {{ itemData.crm_applysource }}
            </div>
            <div class="text-muted font-small-2">
              {{ itemData.brand }} ({{ itemData.location }})
            </div>
            <div class="text-muted font-small-2">
              {{ itemData.license_plate }}
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <div class="font-small-2">
          Konu
        </div>
        <span
          v-for="(subject,key) in itemData.subjects"
          :key="'subject_' + key"
        >
          <b-badge
            variant="light-success"
            pill
          >
            {{ subject }}
          </b-badge>
        </span>
        <div class="font-small-2 mt-1">
          Departman
        </div>
        <span
          v-for="(department,key) in itemData.departments"
          :key="'department_' + key"
        >
          <b-badge
            variant="light-primary"
            pill
          >
            {{ department }}
          </b-badge>
        </span>
      </b-col>
      <b-col>
        <div class="font-small-2">
          Atanan Yetkili
        </div>
        <span
          v-for="(user,key) in itemData.users"
          :key="'user_' + key"
        >
          <b-badge
            variant="light-danger"
            pill
          >
            {{ user }}
          </b-badge>
        </span>
        <div class="font-small-2 mt-1">
          Onay Yetkilisi
        </div>
        <div class="text-primary font-weight-bold">
          {{ itemData.confirmation_user }}
        </div>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import {
  BListGroupItem, BRow, BCol, BButton, BBadge, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'ServiceHistory',
  components: {
    BListGroupItem,
    BRow,
    BCol,
    BButton,
    BBadge,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
}
</script>

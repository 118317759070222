<template>
  <b-form-group
    label="İşlem Tipi"
    label-for="id_com_service_type"
  >
    <validation-provider
      #default="{ errors }"
      name="İşlem Tipi"
      rules="required"
    >
      <v-select
        id="id_com_service_type"
        v-model="service.id_com_service_type"
        label="title"
        :reduce="item => item.id"
        :options="serviceTypes"
        placeholder="Seçiniz"
        multiple
      />
      <small class="text-danger">{{ errors[0] }}</small>
    </validation-provider>
  </b-form-group>
</template>

<script>
import {
  BFormGroup,
} from 'bootstrap-vue'
import vSelect from 'vue-select'
import { ValidationProvider, localize } from 'vee-validate'
import { required } from '@validations'

export default {
  name: 'ServiceTypes',
  components: {
    BFormGroup,
    vSelect,
    ValidationProvider,
  },
  data() {
    return {
      required,
    }
  },
  computed: {
    service() {
      return this.$store.getters['serviceTracing/getService']
    },
    serviceTypes() {
      return this.$store.getters['serviceTypes/getServiceTypes']
    },
  },
  created() {
    this.getServiceTypes()
    localize('tr')
  },
  methods: {
    getServiceTypes() {
      this.$store.dispatch('serviceTypes/serviceTypesList', {
        select: [
          'com_service_type.id AS id',
          'com_service_type.title AS title',
        ],
        where: {
          'com_service_type.hidden': 0,
        },
        order_by: [
          'com_service_type.ordering', 'ASC',
        ],
      })
    },
  },
}
</script>

<template>
  <b-list-group-item>
    <b-row>
      <b-col
        cols="12"
        md="4"
      >
        <div class="d-flex justify-content-start align-items-center">
          <div class="mr-1">
            <b-button
              v-b-tooltip.hover.v-success
              variant="flat-success"
              class="btn-icon"
              pill
              title="Görüntüle"
              target="_blank"
              :href="'/service/view/' + itemData.id"
            >
              <FeatherIcon icon="EyeIcon" />
            </b-button>
          </div>
          <div>
            <div class="font-small-2">
              Sözleşme
            </div>
            <div class="text-danger font-weight-bold">
              {{ itemData.contract_no }}
            </div>
            <div class="text-muted font-small-2">
              {{ moment(itemData.sdate).format('DD.MM.YYYY') }} -
              {{ moment(itemData.edate).format('DD.MM.YYYY') }}
            </div>
            <div class="text-primary font-small-2">
              {{ itemData.contract_status }}
            </div>
          </div>
        </div>
      </b-col>
      <b-col
        cols="12"
        md="4"
      >
        <div class="font-small-2">
          Araç Bilgisi
        </div>
        <div class="text-warning font-weight-bold">
          {{ itemData.arackodu }}
        </div>
        <div class="text-muted font-small-2">
          {{ itemData.licence_plate }}
        </div>
      </b-col>
      <b-col>
        <div class="font-small-2">
          Danışman
        </div>
        <div class="text-primary font-weight-bold">
          {{ itemData.username }}
        </div>
      </b-col>
    </b-row>
  </b-list-group-item>
</template>

<script>
import {
  BListGroupItem, BRow, BCol, BButton, VBTooltip,
} from 'bootstrap-vue'

export default {
  name: 'RentalContracts',
  components: {
    BListGroupItem,
    BRow,
    BCol,
    BButton,
  },
  directives: {
    'b-tooltip': VBTooltip,
  },
  props: {
    itemData: {
      type: Object,
      required: true,
    },
  },
}
</script>
